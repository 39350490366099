import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Stack, Typography } from '@mui/material';

import * as styles from './banner.module.scss';

const Banner = ({ intl: { formatMessage } }) => {
  return (
    <div className={styles.banner}>
      <Stack className={styles.banner__content} spacing={2}>
        <Typography variant="h2" className={styles.banner__title}>
          {formatMessage({ id: 'businessTalentTitle1' })}{' '}
          <span className={styles.primary}>
            {formatMessage({ id: 'businessTalentTitle2' })}
          </span>
        </Typography>
        <Typography variant="h6" className={styles.banner__subtitle}>
          {formatMessage({ id: 'businessTalentSubtitle' })}
        </Typography>
      </Stack>
    </div>
  );
};

Banner.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Banner);
