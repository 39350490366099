// extracted by mini-css-extract-plugin
export var background_images = "banner-module__background_images___tFrak";
export var banner = "banner-module__banner___AhjOC";
export var banner__content = "banner-module__banner__content___55a7Z";
export var banner__subtitle = "banner-module__banner__subtitle___OvoGX";
export var banner__title = "banner-module__banner__title___H0Qru";
export var body_background = "#f8f9fa";
export var card = "banner-module__card___1pkVW";
export var chip = "banner-module__chip___55mBG";
export var container = "banner-module__container___0byag";
export var content = "banner-module__content___WJWMD";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "banner-module__full_img___-ILdY";
export var full_imgSmall = "banner-module__full_img--small___2ntE1";
export var gray_21 = "banner-module__gray_21___+-fFu";
export var image_container = "banner-module__image_container___HhUja";
export var image_item = "banner-module__image_item___83HiT";
export var lg = "1200px";
export var logo = "banner-module__logo___U-yDD";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "banner-module__primary___I4fqF";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "banner-module__title___6te2e";
export var white = "#fff";
export var white_button = "banner-module__white_button___KnHB0";
export var xl = "1536px";
export var xxl = "2500px";